export default function TextInput({
  className,
  value,
  setValue,
  placeholder,
}: {
  className?: string;
  value: number;
  setValue: Function;
  placeholder: string;
}) {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setValue(parseFloat(event.target.value));
    } else {
      setValue(0);
    }
  };

  return (
    <div className="w-full flex items-center justify-end">
      <input
        placeholder={placeholder}
        className={`w-[5rem] h-10 text-center bg-transparent border-b focus:border-gray-400 focus:outline-none text-gray-600 font-normal ${className}`}
        style={{ direction: "ltr" }}
        onChange={onChange}
        defaultValue={value}
      />
    </div>
  );
}
