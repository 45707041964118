import { useState } from "react";

import { PieChart } from "react-minimal-pie-chart";
import { YMInitializer } from "react-yandex-metrika";

import TextInput from "./TextInput";
import { Switch } from "@headlessui/react";

import BLACK_LOGO from "./black_logo.png";

export default function App() {
  var [shopitCommission, setShopitCommission] = useState(8.5);
  var [isShopitPackaging, setIsShopitPackaging] = useState(false);

  var [productCost, setProductCost] = useState(0);

  var [assistantCommission, setAssistantCommission] = useState(0);

  var [intlShippingCost, setIntlShippingCost] = useState(0);

  var [profit, setProfit] = useState(0);

  var packagingCost = isShopitPackaging ? 10 : 0;
  var totalCost = productCost + assistantCommission + intlShippingCost + profit;

  var a = totalCost + (packagingCost + packagingCost * 0.15);
  var b = 1 - (shopitCommission / 100 + (shopitCommission / 100) * 0.15);
  var salePrice = a / b;

  var shopitCommissionValue = salePrice * (shopitCommission / 100) + packagingCost;
  var shopitCommissionValueVat = shopitCommissionValue * 0.15;

  return (
    <div className="relative h-full w-full bg-gray-100 flex flex-col font-[tajawal]">
      <YMInitializer
        accounts={[92704921]}
        options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }}
        version="2"
      />
      <div className="flex flex-col flex-[4]">
        <div className="flex mt-7 justify-center items-center">
          <img src={BLACK_LOGO} className="h-10 w-auto object-contain" alt="" />
          <div className="h-6 w-0.5 bg-gray-400 mx-4" />
          <p className="text-xl text-gray-800">حاسبة البائع</p>
        </div>
        <div className="flex-1 flex justify-center items-center relative">
          <PieChart
            animate={true}
            // animationDuration={200}
            lineWidth={12}
            className="w-[50%] md:w-48 mx-auto"
            totalValue={
              shopitCommissionValue + packagingCost + assistantCommission + intlShippingCost + productCost + profit
            }
            data={[
              {
                value: shopitCommissionValue + shopitCommissionValueVat,
                color: "#cbd5e1",
              },
              { value: packagingCost, color: "#64748b" },
              { value: assistantCommission, color: "#d6d3d1" },
              { value: intlShippingCost, color: "#78716c" },
              { value: productCost, color: "#a1a1aa" },
              { value: profit, color: "#525252" },
            ]}
          />
          <div className="text-gray-800 absolute left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2">
            <p className="text-center mb-1">سعر البيع المقترح</p>
            <p className="text-center text-2xl font-semibold">
              {salePrice.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              SAR
            </p>
            <p className="text-gray-800 text-xs">شامل العمولة والضريبة</p>
          </div>
        </div>
        <center>
          <p className="text-gray-400 text-sm mb-2">
            (عمولة شوب ات شامل الضريبة:{" "}
            {(shopitCommissionValue + shopitCommissionValueVat).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            SAR)
          </p>
        </center>
      </div>
      <div className="flex-[4] md:w-96 md:mx-auto bg-neutral-100 px-4 py-6 rounded-t-3xl shadow-2xl border border-gray-300">
        <div className="w-8 h-1 bg-gray-300 rounded-full mx-auto mb-2" />
        <table className="w-full text-neutral-500 text-md font-medium">
          <tbody>
            <tr>
              <td className="w-[10%] py-2">
                <div className="h-5 w-5 rounded-md bg-slate-300" />
              </td>
              <td className="w-[50%] py-2">عمولة شوب ات</td>
              <td className="w-[40%] py-2">
                <TextInput value={shopitCommission} setValue={setShopitCommission} placeholder="العمولة" />
              </td>
              <td className="py-2">
                <p className="text-gray-400">%</p>
              </td>
            </tr>
            <tr>
              <td className="w-[10%] pb-2">
                <div className="h-5 w-5 rounded-md bg-slate-500" />
              </td>
              <td className="w-[50%] pb-2">تغليف شوب ات؟</td>
              <td className="w-[40%] pb-2 w-full flex justify-end pl-1">
                <Switch
                  checked={isShopitPackaging}
                  onChange={setIsShopitPackaging}
                  className={`${
                    isShopitPackaging ? "bg-slate-500" : "bg-gray-300"
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                  <span
                    className={`${
                      isShopitPackaging ? "-translate-x-6" : "-translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                  />
                </Switch>
              </td>
              <td></td>
            </tr>
            <tr>
              <td className="w-[10%] pb-2">
                <div className="h-5 w-5 rounded-md bg-zinc-400" />
              </td>
              <td className="w-[50%] pb-3">سعر المنتج في البوتيك</td>
              <td className="w-[40%] pb-2">
                <TextInput value={productCost} setValue={setProductCost} placeholder="التكلفة" />
              </td>
              <td className="pb-2">
                <p className="text-gray-400 text-sm">ر.س</p>
              </td>
            </tr>
            <tr>
              <td className="w-[10%] pb-2">
                <div className="h-5 w-5 rounded-md bg-stone-500" />
              </td>
              <td className="w-[50%] pb-3">تكلفة الشحن الدولي</td>
              <td className="w-[40%] pb-2">
                <TextInput value={intlShippingCost} setValue={setIntlShippingCost} placeholder="الشحن" />
              </td>
              <td className="pb-2">
                <p className="text-gray-400 text-sm">ر.س</p>
              </td>
            </tr>
            <tr>
              <td className="w-[10%] pb-2">
                <div className="h-5 w-5 rounded-md bg-stone-300" />
              </td>
              <td className="w-[50%] pb-2">عمولة المساعد</td>
              <td className="w-[40%] pb-2">
                <TextInput value={assistantCommission} setValue={setAssistantCommission} placeholder="العمولة" />
              </td>
              <td>
                <p className="text-gray-400 text-sm">ر.س</p>
              </td>
            </tr>

            <tr className="font-bold">
              <td className="w-[10%] pt-3">
                <div className="h-5 w-5 rounded-md bg-neutral-600" />
              </td>
              <td className="w-[50%] pt-3">عمولتي</td>
              <td className="w-[40%] pt-3">
                <TextInput className="font-semibold" value={profit} setValue={setProfit} placeholder="الربح" />
              </td>
              <td className="pt-3">
                <p className="text-gray-400 text-sm">ر.س</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
